import { Trash2 } from 'lucide-react'
import Button from '../Button/Button'
import './NotificationTypeCard.css'

export default function NotificationTypeCard({ 
  type, 
  onDelete,
  children 
}) {
  return (
    <div className="notification-card">
      <div className="notification-card-header">
        <div className="notification-type">
          <div 
            className="color-dot"
            style={{ backgroundColor: type.fstr_color }}
          />
          <h3 className="notification-name">{type.fstr_notification_type}</h3>
        </div>
        <Button 
          variant="ghost" 
          className="delete-button"
          onClick={() => onDelete(type.flng_notification_type_key)}
        >
          <Trash2 className="delete-icon" />
        </Button>
      </div>
      <div className="notification-card-content">
        <p className="notification-description">{type.fstr_description}</p>
        <div className="message-count">
          {type.fint_messages_sent || 0} messages sent
        </div>
      </div>
    </div>
  )
}

