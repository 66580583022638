import { useState } from 'react'
import Button from '../Button/Button'
import Input from '../Input/Input'
import Label from '../Label/Label'
import { Dialog } from '../Dialog/Dialog'
import './CreateNotificationDialog.css'

export default function CreateNotificationDialog({
  isOpen,
  onClose,
  onCreate
}) {
  const [newType, setNewType] = useState({
    name: '',
    description: '',
    color: '#FFD700'
  })

  const colorOptions = [
    { label: 'Gold', value: '#FFD700' },
    { label: 'Green', value: '#4B9F5B' },
    { label: 'Red', value: '#DC2626' },
    { label: 'Blue', value: '#3B82F6' },
    { label: 'Purple', value: '#8B5CF6' }
  ]

  const handleCreate = () => {
    onCreate(newType);
    setNewType({ name: '', description: '', color: '#FFD700' });
    onClose();
  };


  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Create Notification Type"
    >
      <div className="create-notification-form">
        <div className="create-notification-form-group">
          <Label htmlFor="name">Name</Label>
          <Input
            id="name"
            placeholder="e.g., Special Offers"
            value={newType.name}
            onChange={(e) => setNewType({ ...newType, name: e.target.value })}
          />
        </div>

        <div className="create-notification-form-group">
          <Label htmlFor="description">Description</Label>
          <Input
            id="description"
            placeholder="e.g., Promotional messages and special deals"
            value={newType.description}
            onChange={(e) => setNewType({ ...newType, description: e.target.value })}
          />
        </div>

        <div className="create-notification-form-group">
          <Label htmlFor="color">Color</Label>
          <div className="create-notification-color-options">
            {colorOptions.map((color) => (
              <button
                key={color.value}
                type="button"
                className={`create-notification-color-option ${newType.color === color.value ? 'selected' : ''}`}
                style={{ '--color': color.value }}
                onClick={() => setNewType({ ...newType, color: color.value })}
              >
                <span className="create-notification-color-preview" />
                <span className="create-notification-color-label">{color.label}</span>
              </button>
            ))}
          </div>
        </div>

        <div className="create-notification-dialog-actions">
          <Button  onClick={onClose}
            className="create-notification-cancel-button">
            Cancel
          </Button>
          <Button

            onClick={handleCreate}
            disabled={!newType.name || !newType.description}
            className="create-notification-confirm-button"
          >
            Create Type
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

