const isDevelopment = process.env.NODE_ENV === 'development';

export const msalConfig = {
    auth: {
        clientId: '7bdb249e-73c3-4df5-9b1e-6a4419f571fa',
        authority: 'https://bulkbuzz.b2clogin.com/bulkbuzz.onmicrosoft.com/B2C_1_signupsignin', // Base authority
        knownAuthorities: ["bulkbuzz.b2clogin.com"],
        redirectUri: isDevelopment
            ? 'https://localhost:3000/authRedirect' // Local development
            : 'https://bulkbuzz.net/authRedirect',   // Production server
        navigateToLoginRequestUrl: false,
        postLogoutRedirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: 'sessionStorage', // or 'localStorage'
        storeAuthStateInCookie: false, // Set to true if you're having issues on IE11 or Edge
    },
};

export const loginRequest = {
    scopes: ["openid", "offline_access"], // Adjust scopes as needed
};

export const signUpRequest = {
    authority: 'https://bulkbuzz.b2clogin.com/bulkbuzz.onmicrosoft.com/B2C_1_Sign_Up', // Sign-up user flow
    scopes: ["openid", "offline_access"], // Scopes for sign-up
};

export default msalConfig;
