import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from "@azure/msal-browser";
import { useEffect, useState } from 'react';

import Layout from './components/Global/Layout.jsx';
import DashboardLayout from './components/Dashboard/DashboardLayout';

import Home from './pages/LandingPage';
import Dashboard from './pages/Dashboard'; // Dashboard's default component
import Subscribers from './pages/Subscribers';
import BusinessSettings from './pages/BusinessSettings';
import NotificationTypes from './pages/NotificationTypes';
import Subscription from './pages/Subscription';
import ChooseSubscription from './pages/ChooseSubscription';
import ChooseSubscriptionPublic from './pages/ChooseSubscriptionPublic';
import BusinessSelect from './pages/BusinessSelect.jsx';
import Subscribe from './pages/Subscribe';
import AuthRedirect from './pages/authRedirect';
import TermsAndConditions from './components/Subscribe/TermsAndConditions.jsx';
import PrivacyPolicy from './components/Subscribe/PrivacyPolicy.jsx';
import './styles/LoadingScreen/LoadingScreen.css';

const PrivateRoute = ({ children }) => {
  const { instance, inProgress } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAccount = async () => {
      if (!activeAccount && inProgress === InteractionStatus.None) {
        try {
          await instance.loginRedirect({
            authority: "https://bulkbuzz.b2clogin.com/bulkbuzz.onmicrosoft.com/B2C_1_signupsignin",
            scopes: ["openid", "offline_access"],
          });
        } catch (error) {
          console.error("Error during loginRedirect:", error);
        }
      } else if (activeAccount) {
        setLoading(false);
      }
    };

    checkAccount();
  }, [activeAccount, inProgress, instance]);

  if (loading || inProgress !== InteractionStatus.None) {
    return (
      <Layout bodyStyles={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className="honeycomb">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </Layout>);
  }

  return activeAccount ? children : null;
};

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route
          path="/Home"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/businesses"
          element={
            <Layout bodyStyles={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <BusinessSelect />
            </Layout>
          }
        />
        <Route
          path="/subscribe"
          element={
            <Layout bodyStyles={{ justifyContent: 'center', alignItems: 'center', overflowY: 'auto', height: 'auto' }}>
              <Subscribe />
            </Layout>
          }
        />
        <Route
          path="/choose-subscription"
          element={
            <Layout bodyStyles={{ overflowY: 'auto' }}>
              <ChooseSubscriptionPublic />
            </Layout>
          }
        />
        <Route
          path="/terms-and-conditions"
          element={
            <Layout>
              <TermsAndConditions />
            </Layout>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <Layout>
              <PrivacyPolicy />
            </Layout>
          }
        />
        <Route
          path="/authRedirect"
          element={
            <Layout bodyStyles={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <AuthRedirect />
            </Layout>
          }
        />

        {/* Private Routes */}
        <Route
          path="/dashboard/*"
          element={
            <PrivateRoute>
              <Layout bodyStyles={{ overflowY: 'hidden' }}>
                <DashboardLayout />
              </Layout>
            </PrivateRoute>
          }
        >
          <Route index element={<Layout bodyStyles={{ overflowY: 'hidden' }}><Dashboard /></Layout>} /> {/* Default dashboard route */}
          <Route path="Subscribers" element={<Layout bodyStyles={{ overflowY: 'auto' }}> <Subscribers /></Layout>} />
          <Route path="BusinessSettings" element={<Layout bodyStyles={{ overflowY: 'auto' }}><BusinessSettings /></Layout>} />
          <Route path="NotificationTypes" element={<Layout bodyStyles={{ overflowY: 'auto' }}> <NotificationTypes /></Layout>} />
          <Route path="Subscription" element={<Layout bodyStyles={{ overflowY: 'auto' }}> <Subscription /></Layout>} />
          <Route path="ChooseSubscription" element={<Layout bodyStyles={{ overflowY: 'auto' }}><ChooseSubscription /></Layout>} />
        </Route>

        {/* Default Route */}
        <Route path="*" element={<Navigate to="/Home" />} />
      </Routes>
    </Router>
  );
};

export default App;
