import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { CreditCard, CheckCircle2, AlertCircle, ArrowRight, Loader2, MessageSquare, Users, BadgeHelp, Zap } from 'lucide-react'
import Button from "../components/ui/Button/Button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/Card/Card";

import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "../components/ui/Alert/Alert";

import { Progress } from "../components/ui/Progress/Progress";

export function SubscriptionActive({ subscriptionData })  {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();
  
  const handleUpgrade = async () => {
    setIsLoading(true)
    try {
        navigate('/dashboard/ChooseSubscription')
    } catch (error) {
      console.error('Error:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="sa-container">
    <div className="sa-header">
      <h1 className="sa-title">Subscription Management</h1>
      <p className="sa-description">
        Manage your subscription and monitor your usage
      </p>
    </div>

    <Card className="sa-current-plan-card">
    <CardHeader className="sa-card-header">
      <CardTitle className="sa-card-title">
        <CreditCard className="sa-card-icon" />
        Current Plan
      </CardTitle>
      <CardDescription className="sa-card-description">
        You are currently on the {subscriptionData.fstr_plan_type} plan
      </CardDescription>
    </CardHeader>
    <CardContent>
      <div className="sa-plan-details">
        <div className="sa-plan-overview">
          <div>
            <p className="sa-plan-name">{subscriptionData.fstr_plan_type} Plan</p>
            <p className="sa-plan-renewal">
              Renews on {new Date(subscriptionData.fdtm_subscription_end_date).toLocaleDateString()}
            </p>
          </div>
          <Alert className="sa-alert-status">
            <CheckCircle2 className="sa-alert-icon" />
            <AlertTitle>Active</AlertTitle>
          </Alert>
        </div>

        <div className="sa-plan-usage">
          <div className="sa-usage-item">
            <div className="sa-usage-label">
              <span>Messages Remaining</span>
              <span>
                    {subscriptionData.flng_smstext_remaining} /
                    {subscriptionData.flng_smstext_allowance}
                  </span>
            </div>
            <Progress
              value={(subscriptionData.flng_smstext_remaining /subscriptionData.flng_smstext_allowance) *100}
              className="sa-usage-bar"
            />
          </div>
        </div>
      </div>
    </CardContent>
    <CardFooter>
      <Button
        onClick={handleUpgrade}
        disabled={isLoading}
        className="sa-upgrade-button"
      >
        {isLoading ? (
          <>
            <Loader2 className="button-icon spinning" />
            Processing...
          </>
        ) : (
          <>
            Upgrade Plan
            <ArrowRight className="button-icon" />
          </>
        )}
      </Button>
    </CardFooter>
  </Card>

  {/* Features Grid */}
  <div className="sa-features-grid">
    <Card className="sa-feature-card">
      <CardHeader className="sa-feature-header">
      <div className="sa-feature-header-content">
        <MessageSquare className="sa-feature-icon" />
        <CardTitle className="sa-feature-title">Messaging</CardTitle>
        </div>    
      </CardHeader>
      <CardContent>
        <ul className="sa-feature-list">
          <li className="sa-feature-item">
            <CheckCircle2 className="sa-feature-check-icon" />
            Up to {subscriptionData.flng_SMSText_allowance} messages/month
          </li>
          <li className="sa-feature-item">
            <CheckCircle2 className="sa-feature-check-icon" />
            Custom notification types
          </li>
          <li className="sa-feature-item">
            <CheckCircle2 className="sa-feature-check-icon" />
            Message scheduling
          </li>
        </ul>
      </CardContent>
    </Card>
    <Card className="sa-feature-card">

    <CardHeader className="sa-feature-header">
      <div className="sa-feature-header-content">
        <Users className="sa-feature-icon" />
        <CardTitle className="sa-feature-title">Subscribers</CardTitle>
        </div>    
      </CardHeader> 
        <CardContent>
          <ul className="sa-feature-list">
              <li className="sa-feature-item">
                <CheckCircle2 className="sa-feature-check-icon" />
                <span>Unlimited subscribers</span>
              </li>
              <li className="sa-feature-item">
                <CheckCircle2 className="sa-feature-check-icon" />
                <span>Subscriber analytics</span>
              </li>
            </ul>
          </CardContent>
        </Card>

        <Card className="sa-feature-card">

        <CardHeader className="sa-feature-header">
      <div className="sa-feature-header-content">
        <Zap className="sa-feature-icon" />
        <CardTitle className="sa-feature-title">Features</CardTitle>
        </div>    
      </CardHeader> 
          <CardContent>
          <ul className="sa-feature-list">
              <li className="sa-feature-item">
                <CheckCircle2 className="sa-feature-check-icon" />
                <span>Advanced analytics</span>
              </li>
              <li className="sa-feature-item">
                <CheckCircle2 className="sa-feature-check-icon" />
                <span>API access</span>
              </li>
              <li className="sa-feature-item">
                <CheckCircle2 className="sa-feature-check-icon" />
                <span>Custom integrations</span>
              </li>
            </ul>
          </CardContent>
        </Card>

        <Card className="sa-feature-card">

        <CardHeader className="sa-feature-header">
      <div className="sa-feature-header-content">
        <BadgeHelp className="sa-feature-icon" />
        <CardTitle className="sa-feature-title">Support</CardTitle>
        </div>    
      </CardHeader>       
          <CardContent>
          <ul className="sa-feature-list">
              <li className="sa-feature-item">
                <CheckCircle2 className="sa-feature-check-icon" />
                <span>Priority email support</span>
              </li>
              <li className="sa-feature-item">
                <CheckCircle2 className="sa-feature-check-icon" />
                <span>Phone support</span>
              </li>
              <li className="sa-feature-item">
                <CheckCircle2 className="sa-feature-check-icon" />
                <span>Dedicated account manager</span>
              </li>
            </ul>
          </CardContent>
        </Card>



      {/* Usage Warning */}
      {(subscriptionData.messagesUsed / subscriptionData.messagesLimit > 0.9 ||
        subscriptionData.subscribers / subscriptionData.subscribersLimit > 0.9) && (
        <Alert className="warning-alert">
          <AlertCircle className="warning-icon" />
          <AlertTitle>Usage Warning</AlertTitle>
          <AlertDescription>
            You are approaching your plan limits. Consider upgrading to avoid service interruption.
          </AlertDescription>
        </Alert>
      )}
    </div>
    </div>
  )
}

