import './Label.css';

export default function Label({ className = '', ...props }) {
    return (
      <label 
        className={`label ${className}`}
        {...props}
      />
    )
  }
  
  