import { useState, useEffect } from 'react';
import { SubscriptionInactive } from './SubscriptionInactive';
import { SubscriptionActive } from './SubscriptionActive';
import "../styles/Subscription/MasterStyle.css";

export default function SubscriptionPage() {
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/get-subscription-status`, 
          {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include', // Include cookies if needed
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch subscription data');
        }

        const data = await response.json();
        setSubscriptionData(data);
      } catch (error) {
        console.error('Error fetching subscription:', error);
        setSubscriptionData(null); // If no subscription, show inactive state
      } finally {
        setIsLoading(false);
      }
    };

    fetchSubscription();
  }, []);

  if (isLoading) {
    return (
      <div className="subscription-loading">
        <div className="loading-spinner"></div>
        <p>Loading subscription details...</p>
      </div>
    );
  }

  if (subscriptionData) {
    // Pass the subscription data to SubscriptionActive regardless of status
    return <SubscriptionActive subscriptionData={subscriptionData} />;
  }
  // If no subscription data, fallback to SubscriptionInactive
  return <SubscriptionInactive />;
}
