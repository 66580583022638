import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Rocket, Loader2, MessageSquare, Users, BarChart3, Clock, CheckCircle2, ArrowRight, Shield } from 'lucide-react'
import Button from "../components/ui/Button/Button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/Card/Card";

export function SubscriptionInactive() {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();

  const handleSubscribe = async () => {
    setIsLoading(true);
    try {
      navigate('/dashboard/ChooseSubscription')
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <div class="si-container">
     {/* Hero Section */}
<Card className="si-hero">
  <CardContent className="si-hero-content">
    <div className="si-hero-container">
      <div className="si-hero-text">
        <div className="si-hero-badge">
          <Rocket className="si-hero-icon-rocket" />
          Get Started Today
        </div>
        <h1 className="si-hero-title">Start Connecting with Your Customers</h1>
        <p className="si-hero-description">
          Choose a subscription plan to begin sending messages to your customers.
          Our flexible plans are designed to grow with your business.
        </p>
        <Button
          onClick={handleSubscribe}
          disabled={isLoading}
          className="si-hero-button"
        >
          {isLoading ? (
            <>
              <Loader2 className="si-hero-icon-loader" />
              Processing...
            </>
          ) : (
            <>
              View Plans
              <ArrowRight className="si-hero-icon-arrow" />
            </>
          )}
        </Button>
      </div>
      <div className="si-hero-status">
        <Card className="si-hero-status-card">
          <CardHeader className="si-hero-status-header">
            <Shield className="si-hero-icon-shield" />
            <CardTitle className="si-hero-status-title">No Subscription Active</CardTitle>
            </CardHeader>
          <CardDescription className="si-hero-status-description">
            Subscribe now to start sending messages to your customers
          </CardDescription>

        </Card>
      </div>
    </div>
  </CardContent>
</Card>

    {/* si-features Grid */}
<div className="si-features-grid">
  {/* Powerful Messaging si-feature */}
  <Card className="si-feature-card">
    <CardHeader className="si-feature-header">
    <div className="si-feature-header-content">
      <MessageSquare className="si-feature-icon" />
      <CardTitle className="si-feature-title">Powerful Messaging</CardTitle>
    </div>    
    </CardHeader>
    <CardContent>
    <CardDescription className="si-feature-description">
        Connect with your customers through SMS notifications
    </CardDescription>
      <ul className="si-feature-list">
        <li className="si-feature-item">
          <CheckCircle2 className="si-feature-check-icon" />
          Flexible message volumes
        </li>
        <li className="si-feature-item">
          <CheckCircle2 className="si-feature-check-icon" />
          Scheduled messages
        </li>
        <li className="si-feature-item">
          <CheckCircle2 className="si-feature-check-icon" />
          Message templates
        </li>
      </ul>
    </CardContent>
  </Card>

  {/* Subscriber Management si-feature */}
  <Card className="si-feature-card">
    <CardHeader className="si-feature-header">
    <div className="si-feature-header-content">
      <Users className="si-feature-icon" />
      <CardTitle className="si-feature-title">Subscriber Management</CardTitle>
    </div>
    </CardHeader>
    <CardContent>
      <CardDescription className="si-feature-description">
        Powerful tools to manage your growing subscriber base
      </CardDescription>
      <ul className="si-feature-list">
        <li className="si-feature-item">
          <CheckCircle2 className="si-feature-check-icon" />
          Scalable subscriber lists
        </li>
        <li className="si-feature-item">
          <CheckCircle2 className="si-feature-check-icon" />
          Group segmentation
        </li>
        <li className="si-feature-item">
          <CheckCircle2 className="si-feature-check-icon" />
          Subscriber analytics
        </li>
      </ul>
    </CardContent>
  </Card>

  {/* Advanced Analytics si-feature */}
  <Card className="si-feature-card">
    <CardHeader className="si-feature-header">
    <div className="si-feature-header-content">
      <BarChart3 className="si-feature-icon" />
      <CardTitle className="si-feature-title">Advanced Analytics</CardTitle>
      </div>
    </CardHeader>
    <CardContent>
      <CardDescription className="si-feature-description">
        Get insights into your messaging performance
      </CardDescription>
      <ul className="si-feature-list">
        <li className="si-feature-item">
          <CheckCircle2 className="si-feature-check-icon" />
          Delivery tracking
        </li>
        <li className="si-feature-item">
          <CheckCircle2 className="si-feature-check-icon" />
          Engagement metrics
        </li>
        <li className="si-feature-item">
          <CheckCircle2 className="si-feature-check-icon" />
          Custom reports
        </li>
      </ul>
    </CardContent>
  </Card>

  {/* Business Tools si-feature */}
  <Card className="si-feature-card">
    <CardHeader className="si-feature-header">
    <div className="si-feature-header-content">
      <Clock className="si-feature-icon" />
      <CardTitle className="si-feature-title">Business Tools</CardTitle>
      </div>
    </CardHeader>
    <CardContent>
      <CardDescription className="si-feature-description">
        Everything you need to manage your communications
      </CardDescription>
      <ul className="si-feature-list">
        <li className="si-feature-item">
          <CheckCircle2 className="si-feature-check-icon" />
          Message scheduling
        </li>
        <li className="si-feature-item">
          <CheckCircle2 className="si-feature-check-icon" />
          Auto-responses
        </li>
        <li className="si-feature-item">
          <CheckCircle2 className="si-feature-check-icon" />
          API integration
        </li>
      </ul>
    </CardContent>
  </Card>
</div>
  
   {/* Bottom CTA */}
    <Card class="si-cta-card">
      <CardContent class="si-cta-content">
        <h2 class="si-cta-title">Ready to Start Messaging?</h2>
        <p class="si-cta-description">
          Choose a plan that works best for your business. Our flexible options are designed to scale with your needs.
        </p>
        <Button class="si-cta-button"  onClick={handleSubscribe}>
          <span>Choose Your Plan</span>
          <ArrowRight className="si-hero-icon-arrow" />
        </Button>
      </CardContent>
    </Card>
  </div>
  
  )
}

