import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons';
import '../styles/BusinessSelect/MasterStyle.css'; 

const BusinessSelect = () => {
    const [businesses, setBusinesses] = useState([]);
    const [zipCode, setZipCode] = useState(''); // State for zip code
    const [selectedBusiness, setSelectedBusiness] = useState(null);
    const navigate = useNavigate(); // Use navigate from react-router
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const fetchBusinesses = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/businesses${zipCode ? `?zip=${zipCode}` : ''}`);

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                setBusinesses(data);
            } catch (error) {
                console.error('Error fetching businesses:', error);
            }
        };

        fetchBusinesses();

    }, [zipCode]);

    const handleBusinessSelect = () => {
        
        const selectedBusinessObj = businesses.find(
            (business) => business.flng_customer_key === Number(selectedBusiness)
        );

        if (selectedBusiness) {
            navigate(`/subscribe?customerKey=${selectedBusinessObj.flng_customer_key}&zip=${selectedBusinessObj.fstr_zip}&businessName=${selectedBusinessObj.fstr_business_name}`);
        } else {
            alert('Please select a business and enter a zip code to subscribe.');
        }
    };

    return (
       <div className="business-select-container">

        <header className="business-select-card-header">
          <h1 className="business-select-card-title">Bulk Buzz</h1>
          <p className="business-select-card-subtitle">What's All The Buzz?</p>
        </header>
        <div className="business-select-card">
        <div className="business-select-card-content">
        <p class="instructions">Select a business or enter a zip code to find a business to subscribe to.</p>
          <div className="business-select-input-group">
            <label>Search by Business Name</label>
            <select
              value={selectedBusiness}
              onChange={(e) => setSelectedBusiness(e.target.value)}
              className="business-select"
            >
              <option value="">Select a business</option>
              {businesses.map((business) => (
                <option
                  key={business.flng_customer_key}
                  value={business.flng_customer_key.toString()}
                >
                  {business.fstr_business_name}
                </option>
              ))}
            </select>
          </div>

          <div className="business-select-input-group">
            <label>Search by Zip Code</label>
            <div className="business-select-input-wrapper">
            <FontAwesomeIcon icon={faMagnifyingGlass} className="business-select-icon"/>
              <input
                type="text"
                placeholder="Enter zip code"
                value={zipCode}
                onChange={(e) => {
                  const input = e.target.value
                  if (/^\d*$/.test(input)) {
                    setZipCode(input)
                  }
                }}
                className="business-select-zip-input"
                maxLength={5}
              />
            </div>
          </div>

          <button
            className="action-button"
            onClick={handleBusinessSelect}
            disabled={isLoading}
          >
            {isLoading ? 'Searching...' : 'Proceed to Subscribe'}
          </button>

          {isLoading && <p className="loading-text">Searching for businesses...</p>}
        </div>
      </div>
    </div>
  );
};

export default BusinessSelect;
