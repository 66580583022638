import React, { useState, useEffect, useRef } from 'react';
import JSConfetti from 'js-confetti';
import { useLocation } from 'react-router-dom';
import { Bell, CheckCircle2, Loader2 } from 'lucide-react'
import '../styles/Subscribe/MasterStyle.css';

export default function Subscribe () {

  const [formData, setFormData] = useState({
    phoneNumber: '',
    firstName: '',
    lastName: '',
    email: '',
  })
  const [consents, setConsents] = useState({
    expressConsent: false,
    termsAndConditions: false,
  })
  const [error, setError] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [isSending, setIsSending] = useState(false);
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [selectedNotifications, setSelectedNotifications] = useState({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const customerKey = queryParams.get('customerKey');
  const zipCode = queryParams.get('zip');
  const businessName = queryParams.get('businessName');

  useEffect(() => {
    const fetchNotificationTypes = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/subscribe-list/${customerKey}/notification-types`);
        const data = await response.json();
        setNotificationTypes(data);
        // Initialize selected notifications state
        const initialSelected = data.reduce((acc, type) => {
          acc[type.fstr_notification_type] = false; // Initialize with false (not selected)
          return acc;
        }, {});
        setSelectedNotifications(initialSelected);
      } catch (error) {
        console.error('Error fetching notification types:', error);
      } finally {
        setIsLoading(false)
      }
    }

    if (customerKey) {
      fetchNotificationTypes();
    }

  }, [customerKey, zipCode]); // Re-fetch if customerKey or zipCode changes


  const handleInputChange = (e) => {
    const { name, value } = e.target

    // Format the phone number if the field is phoneNumber
  const formattedValue = name === 'phoneNumber' ? formatPhoneNumber(value) : value;

  // Validate the email field if the field is email
  if (name === 'email') {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/; // Simple email validation regex
    if (value && !emailRegex.test(value)) {
      setError('Please enter a valid email address.');
    } else {
      setError(''); // Clear error if valid
    }
  }

    setFormData(prev => ({
      ...prev,
      [name]: formattedValue
    }))
    setError('')
  }

  const handleNotificationChange = (name, checked) => {
    setSelectedNotifications(prev => ({
      ...prev,
      [name]: checked
    }))
    setError('')
  }

  const handleConsentChange = (name, checked) => {
    setConsents(prev => ({
      ...prev,
      [name]: checked
    }))
    setError('')
  }

  const validateForm = () => {

    const cleanedPhoneNumber = formData.phoneNumber.replace(/\D/g, ''); 

    if (!cleanedPhoneNumber.match(/^[0-9]{10}$/)) {
      setError('Please enter a valid 10-digit phone number.')
      return false
    }

    if (!formData.email) {
      setError('Please enter your email address.')
      return false
    }

    if (!validateEmail(formData.email)) {
      setError('Please enter a valid email address.');
      return false;
    }

    if (!Object.values(selectedNotifications).some((value) => value)) {
      setError('Please select at least one notification type.');
      return false;
    }

    if (!consents.expressConsent) {
      setError('You must consent to receiving SMS notifications.')
      return false
    }

    if (!consents.termsAndConditions) {
      setError('You must accept the Terms and Conditions and Privacy Policy.')
      return false
    }

    return true
  }

  const handleSubmit  = async (e) => {
    e.preventDefault();

    if (!validateForm()) return

    setIsSending(true);
    setError('');

    formData.phoneNumber = formData.phoneNumber.replace(/\D/g, ''); // Remove all non-digit characters

    // API call to subscribe the user
    // Convert selected notifications (object) into an array of selected notification types
    const selectedNotificationKeys = Object.keys(selectedNotifications)
    .filter((name) => selectedNotifications[name]) // Ensure `true` values are filtered
    .map((name) => {
      const type = notificationTypes.find((t) => t.fstr_notification_type === name);
      return type?.flng_notification_type_key;
    })
    .filter(Boolean);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/subscribe-submit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          selectedNotifications: selectedNotificationKeys,
          expressConsent: consents.expressConsent,
        }),
      });

      if (!response.ok) throw new Error('Subscription failed. Please try again later.')

        setIsSubscribed(true)
        confettiRef.current.addConfetti({
          confettiRadius: 6,
          confettiNumber: 400,
        })
      } catch (error) {
        setError(error.message)
      } finally {
        setIsSending(false)
      }
    }
  
    const canvasRef = useRef();
    const confettiRef = useRef();
    useEffect(() => {
      confettiRef.current = new JSConfetti({ canvas: canvasRef.current });
    }, []);

    useEffect(() => {
      if (isSubscribed) {
        confettiRef.current.addConfetti({
          confettiRadius: 6,
          confettiNumber: 600,
        });
      }
    }, [isSubscribed]);

  if (isSubscribed) {
    return (
      <div className="success-container">
      <div className="success-content">
        <CheckCircle2 className="success-icon" />
        <h2>🎉 Thank you for subscribing!</h2>
        <p>You'll receive notifications from <strong>{businessName}</strong> soon!</p>
        </div>
        <canvas ref={canvasRef} className="confetti-canvas" />
      </div>
    )
  }

  return (
    <div className="subscribe-container">
      <div className="subscribe-card">
        <div className="subscribe-header">
          <Bell className="subscribe-header-icon" />
          <h1>Subscribe to Notifications</h1>
          <p>Stay updated with the latest news and offers from {businessName}!</p>
        </div>

        {isLoading ? (
          <div className="subscribe-loading-state">
            <Loader2 className="subscribe-loading-icon" />
            <p>Loading notification options...</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="subscribe-form">
            <div className="subscribe-form-group">
              <label htmlFor="phoneNumber">Phone Number</label>
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                placeholder="Enter your phone number"
                value={formData.phoneNumber}
                onChange={handleInputChange}
              />
            </div>

            <div className="subscribe-notification-types">
              <label className="section-label">Notification Types</label>
              <div className="checkbox-grid">
                {notificationTypes.map((type) => (
                  <label key={type.flng_notification_type_key} className="checkbox-label">
                    <input
                      type="checkbox"
                      checked={selectedNotifications[type.fstr_notification_type] || false}
                      onChange={(e) => handleNotificationChange(type.fstr_notification_type, e.target.checked)}
                    />
                    <span>{type.fstr_notification_type}</span>
                  </label>
                ))}
              </div>
            </div>

            <div className="subscribe-form-row">
              <div className="subscribe-form-group">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="Enter your first name"
                  value={formData.firstName}
                  onChange={handleInputChange}
                />
              </div>

              <div className="subscribe-form-group">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Enter your last name"
                  value={formData.lastName}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="subscribe-form-group">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email address"
                value={formData.email}
                onChange={handleInputChange}
              />          
            </div>

            <div className="consent-section">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={consents.expressConsent}
                  onChange={(e) => handleConsentChange('expressConsent', e.target.checked)}
                />
                <span>
                  I agree to receive SMS messages from <strong>{businessName}</strong>, including promotional and transactional updates. 
                  Message and data rates may apply. I understand I can reply <strong>STOP</strong> at any time to opt out.
                </span>
              </label>

              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={consents.termsAndConditions}
                  onChange={(e) => handleConsentChange('termsAndConditions', e.target.checked)}
                />
                <span>
                  I accept the <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> and the <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                </span>
              </label>
            </div>

            {error && <div className="error-message">{error}</div>}

            <button type="submit" className="submit-button" disabled={isSending}>
              {isSending ? (
                <>
                  <Loader2 className="button-icon spinning" />
                  Subscribing...
                </>
              ) : (
                'Subscribe Now'
              )}
            </button>
          </form>
        )}
      </div>
    </div>
  )

  function validateEmail (value) {
    // Simple regex to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return emailRegex.test(value);
  }

  function formatPhoneNumber(value) {
    // Remove all non-digit characters
    const cleaned = value.replace(/\D/g, '');
    
    // Format the phone number based on the number of digits
    if (cleaned.length <= 3) {
      return cleaned;
    } else if (cleaned.length <= 6) {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
    } else {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
    }
  }
}