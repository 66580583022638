import { useState } from 'react'
import { Printer, ChevronUp, FileText, Shield, Bell, HelpCircle, Mail } from 'lucide-react'
import '../../styles/Subscribe/TermsAndConditions.css';

const TermsAndConditions = () => {
    const [activeSection, setActiveSection] = useState('')
  
    const sections = [
      {
        id: 'sms-agreement',
        title: 'Agreement to Receive Text Messages',
        icon: Bell,
      },
      {
        id: 'billing',
        title: 'Billing and Charges',
        icon: FileText,
      },
      {
        id: 'opt-in',
        title: 'Opt-In Requirements',
        icon: Shield,
      },
      {
        id: 'privacy',
        title: 'Data Privacy and Usage',
        icon: Shield,
      },
      {
        id: 'contact',
        title: 'Contact Us',
        icon: Mail,
      },
      {
        id: 'updates',
        title: 'Updates to Terms',
        icon: FileText,
      },
    ]
  
    const handlePrint = () => {
      window.print()
    }
  
    const handleScroll = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  
    return (
      <div className="terms-container">
        <div className="terms-header">
          <h1>Terms and Conditions</h1>
          <button onClick={handlePrint} className="print-button" aria-label="Print terms and conditions">
            <Printer className="print-icon" />
            Print
          </button>
        </div>
  
        <div className="terms-layout">
          <nav className="terms-nav">
            <div className="terms-nav-header">
              <FileText className="terms-nav-icon" />
              <h2>Contents</h2>
            </div>
            <ul className="terms-nav-list">
              {sections.map((section) => (
                <li key={section.id}>
                  <a
                    href={`#${section.id}`}
                    className={activeSection === section.id ? 'active' : ''}
                    onClick={() => setActiveSection(section.id)}
                  >
                    <section.icon className="terms-section-icon" />
                    {section.title}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
  
          <div className="terms-content">
            <section id="sms-agreement">
              <h2>Agreement to Receive Text Messages</h2>
              <p>
                By providing your mobile number, you agree that Bulk Buzz may send you periodic SMS or MMS messages containing important information, updates, deals, and specials. This agreement constitutes express written consent to receive such messages under applicable laws, including the Telephone Consumer Protection Act (TCPA).
              </p>
              <div className="terms-list">
                <ul>
                  <li>You will receive up to 5 messages per month unless otherwise specified.</li>
                  <li>You may unsubscribe at any time by texting the word <strong>STOP</strong> to (555) 555-5555. You may receive a subsequent message confirming your opt-out request.</li>
                  <li>For help, text the word <strong>HELP</strong> to (555) 555-5555 or contact support at <a href="mailto:support@bulkbuzz.net">support@bulkbuzz.net</a>.</li>
                  <li>Message and data rates may apply. Please check with your wireless carrier for more details.</li>
                  <li>Participating carriers include but are not limited to AT&T, T-Mobile®, Verizon Wireless, Sprint, Boost, U.S. Cellular®, MetroPCS®, InterOp, Cellcom, C Spire Wireless, Cricket, and Virgin Mobile.</li>
                  <li>T-Mobile is not liable for delayed or undelivered messages.</li>
                  <li>You agree to notify Bulk Buzz of any changes to your mobile number and update your account to reflect this change.</li>
                  <li>Data collected in connection with this SMS service may include your mobile number, carrier's name, and message details (e.g., date and content). This data may be used to manage your subscriptions and provide services requested by you.</li>
                  <li>We reserve the right to modify or terminate this service at any time without prior notice.</li>
                </ul>
              </div>
            </section>
  
            <section id="billing">
              <h2>Billing and Charges</h2>
              <div className="terms-info-card">
                <FileText className="terms-info-icon" />
                <p>
                  Bulk Buzz does not charge you for receiving text messages. However, standard message and data rates may apply depending on your wireless plan. For questions about your messaging rates, please contact your wireless provider.
                </p>
              </div>
            </section>
  
            <section id="opt-in">
              <h2>Opt-In Requirements</h2>
              <p>
                By subscribing, you confirm that you are the authorized user of the mobile device and consent to receive SMS messages. Express written consent is required to receive marketing messages. Consent is not a condition for purchasing any goods or services.
              </p>
            </section>
  
            <section id="privacy">
              <h2>Data Privacy and Usage</h2>
              <div className="terms-info-card">
                <Shield className="terms-info-icon" />
                <p>
                  Bulk Buzz is committed to protecting your personal information. For details on how we collect, use, and share your information, please review our <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                </p>
              </div>
            </section>
  
            <section id="contact">
              <h2>Contact Us</h2>
              <div className="terms-contact-card">
                <HelpCircle className="terms-contact-icon" />
                <p>
                  If you have questions or concerns regarding these Terms and Conditions, please contact us at <a href="mailto:support@bulkbuzz.net">support@bulkbuzz.net</a>.
                </p>
              </div>
            </section>
  
            <section id="updates">
              <h2>Updates to Terms</h2>
              <p>
                Bulk Buzz may update these Terms and Conditions from time to time to reflect changes in regulations or service practices. Please review this document periodically for updates. Continued use of the service constitutes your acceptance of any changes.
              </p>
            </section>
          </div>
        </div>
  
        <button onClick={handleScroll} className="terms-scroll-top" aria-label="Scroll to top">
          <ChevronUp />
        </button>
      </div>
    )
  }
  
  export default TermsAndConditions
  
  
