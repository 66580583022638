import { AlertCircle } from 'lucide-react'
import './EmptyState.css'

export default function EmptyState() {
  return (
    <div className="empty-state">
      <AlertCircle className="empty-state-icon" />
      <h3 className="empty-state-title">No notification types</h3>
      <p className="empty-state-text">
        Create your first notification type to start organizing your messages.
      </p>
    </div>
  )
}

