import { useState } from 'react'
import { Printer, ChevronUp, FileText, Shield, Mail, User, Database, Cookie, Share2, Key, Clock, Lock, Globe, Users, RefreshCw } from 'lucide-react'
import '../../styles/Subscribe/PrivacyPolicy.css';

const PrivacyPolicy = () => {
  const [activeSection, setActiveSection] = useState('')

  const sections = [
    {
      id: 'information-collected',
      title: 'Information We Collect',
      icon: Database,
      subsections: ['Personal Information', 'Automatic Collection', 'Cookies']
    },
    {
      id: 'information-usage',
      title: 'How We Use Your Information',
      icon: FileText
    },
    {
      id: 'information-sharing',
      title: 'Sharing Your Information',
      icon: Share2
    },
    {
      id: 'your-rights',
      title: 'Your Rights',
      icon: Key
    },
    {
      id: 'data-retention',
      title: 'Data Retention',
      icon: Clock
    },
    {
      id: 'security',
      title: 'Security',
      icon: Lock
    },
    {
      id: 'cross-border',
      title: 'Cross-Border Data Transfers',
      icon: Globe
    },
    {
      id: 'children',
      title: "Children's Privacy",
      icon: Users
    },
    {
      id: 'changes',
      title: 'Changes to This Policy',
      icon: RefreshCw
    },
    {
      id: 'contact',
      title: 'Contact Us',
      icon: Mail
    }
  ]

  const handlePrint = () => {
    window.print()
  }

  const handleScroll = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <div className="privacy-container">
      <div className="privacy-header">
        <div className="privacy-header-content">
          <h1>Privacy Policy</h1>
          <div className="privacy-header-meta">
            <Shield className="privacy-header-icon" />
            <span>Effective Date: December 27, 2024</span>
          </div>
        </div>
        <button onClick={handlePrint} className="print-button" aria-label="Print privacy policy">
          <Printer className="print-icon" />
          Print
        </button>
      </div>

      <div className="privacy-intro">
        <p>
          Thank you for using Bulk Buzz. Your privacy is important to us. This Privacy Policy outlines how we collect, use, and protect your information when you use our services.
        </p>
      </div>

      <div className="privacy-layout">
        <nav className="privacy-nav">
          <div className="privacy-nav-header">
            <Shield className="privacy-nav-icon" />
            <h2>Contents</h2>
          </div>
          <ul className="privacy-nav-list">
            {sections.map((section) => (
              <li key={section.id}>
                <a
                  href={`#${section.id}`}
                  className={activeSection === section.id ? 'active' : ''}
                  onClick={() => setActiveSection(section.id)}
                >
                  <section.icon className="privacy-section-icon" />
                  {section.title}
                </a>
                {section.subsections && (
                  <ul className="privacy-subsection-list">
                    {section.subsections.map((subsection) => (
                      <li key={subsection}>
                        <a href={`#${section.id}-${subsection.toLowerCase().replace(' ', '-')}`}>
                          {subsection}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </nav>

        <div className="privacy-content">
          <section id="privacy-information-collected">
            <h2><Database className="privacy-section-title-icon" /> Information We Collect</h2>
            
            <div id="information-collected-personal-information" className="privacy-subsection">
              <h3><User className="privacy-subsection-icon" /> Personal Information</h3>
              <div className="privacy-info-card">
                <p>We collect personal information you provide directly, including:</p>
                <ul>
                  <li>Name</li>
                  <li>Email address</li>
                  <li>Phone number</li>
                  <li>Address</li>
                  <li>Notification preferences</li>
                </ul>
              </div>
            </div>

            <div id="information-collected-automatic-collection" className="privacy-subsection">
              <h3><Database className="privacy-subsection-icon" /> Automatically Collected Information</h3>
              <div className="privacy-info-card">
                <p>When you use our service, we may automatically collect:</p>
                <ul>
                  <li>IP address</li>
                  <li>Browser type and version</li>
                  <li>Device information (e.g., operating system, device type)</li>
                  <li>Log data (e.g., pages visited, actions taken, timestamps)</li>
                </ul>
              </div>
            </div>

            <div id="information-collected-cookies" className="privacy-subsection">
              <h3><Cookie className="privacy-subsection-icon" /> Cookies and Tracking Technologies</h3>
              <div className="privacy-info-card">
                <p>We use cookies, web beacons, and similar technologies to:</p>
                <ul>
                  <li>Enhance user experience</li>
                  <li>Analyze website usage</li>
                  <li>Provide tailored content</li>
                </ul>
                <p>You can control cookies through your browser settings.</p>
              </div>
            </div>
          </section>

          <section id="information-usage">
            <h2><FileText className="privacy-section-title-icon" /> How We Use Your Information</h2>
            <div className="privacy-info-card">
              <p>We use your information to:</p>
              <ul>
                <li>Deliver SMS or MMS notifications based on your preferences</li>
                <li>Personalize your experience with relevant content and offers</li>
                <li>Improve our services and troubleshoot issues</li>
                <li>Communicate updates, promotions, and other important information</li>
                <li>Comply with legal obligations and enforce our terms</li>
              </ul>
            </div>
          </section>

          <section id="information-sharing">
            <h2><Share2 className="privacy-section-title-icon" /> Sharing Your Information</h2>
            
            <div className="privacy-subsection">
              <h3>Service Providers</h3>
              <div className="privacy-info-card">
                <p>We work with trusted third parties to:</p>
                <ul>
                  <li>Send notifications</li>
                  <li>Host our platform</li>
                  <li>Provide analytics and support</li>
                </ul>
              </div>
            </div>

            <div className="privacy-subsection">
              <h3>Legal Compliance</h3>
              <div className="privacy-info-card">
                <p>We may disclose your information:</p>
                <ul>
                  <li>To comply with legal processes</li>
                  <li>To protect our rights or property</li>
                  <li>In connection with a merger, acquisition, or sale of assets</li>
                </ul>
              </div>
            </div>
          </section>

          <section id="your-rights">
            <h2><Key className="privacy-section-title-icon" /> Your Rights</h2>
            
            <div className="privacy-rights-grid">
              <div className="privacy-rights-card">
                <h3>Access and Update</h3>
                <p>You can request access to or correction of your personal information by contacting us at <a href="mailto:support@bulkbuzz.net">support@bulkbuzz.net</a>.</p>
              </div>

              <div className="privacy-rights-card">
                <h3>Deletion</h3>
                <p>You can request that we delete your personal information unless retention is required by law.</p>
              </div>

              <div className="privacy-rights-card">
                <h3>Opt-Out</h3>
                <p>You may opt out of receiving marketing messages by:</p>
                <ul>
                  <li>Texting <strong>STOP</strong> to our service number</li>
                  <li>Updating your notification preferences in your account</li>
                </ul>
              </div>
            </div>
          </section>

          <section id="data-retention">
            <h2><Clock className="privacy-section-title-icon" /> Data Retention</h2>
            <div className="privacy-info-card highlight">
              <p>We retain your personal information as long as needed to provide our services or comply with legal requirements. Non-identifiable information may be retained indefinitely.</p>
            </div>
          </section>

          <section id="security">
            <h2><Lock className="privacy-section-title-icon" /> Security</h2>
            <div className="privacy-info-card highlight">
              <p>We implement industry-standard security measures to protect your data. However, no system is entirely secure, and we cannot guarantee absolute protection.</p>
            </div>
          </section>

          <section id="cross-border">
            <h2><Globe className="privacy-section-title-icon" /> Cross-Border Data Transfers</h2>
            <div className="privacy-info-card">
              <p>Your data may be transferred and processed in countries outside your residence. By using our service, you consent to such transfers.</p>
            </div>
          </section>

          <section id="children">
            <h2><Users className="privacy-section-title-icon" /> Children's Privacy</h2>
            <div className="privacy-info-card warning">
              <p>Our services are not intended for children under 13. We do not knowingly collect personal information from children. Contact us if you believe we have inadvertently collected such data.</p>
            </div>
          </section>

          <section id="changes">
            <h2><RefreshCw className="privacy-section-title-icon" /> Changes to This Policy</h2>
            <div className="privacy-info-card">
              <p>We may update this Privacy Policy periodically. Any changes will be posted on this page with the revised date. Continued use of our services constitutes your acceptance of these updates.</p>
            </div>
          </section>

          <section id="contact">
            <h2><Mail className="privacy-section-title-icon" /> Contact Us</h2>
            <div className="privacy-contact-card">
              <p>For questions or concerns about this Privacy Policy, please contact us at:</p>
              <ul>
                <li>Email: <a href="mailto:support@bulkbuzz.net">support@bulkbuzz.net</a></li>
              </ul>
            </div>
          </section>
        </div>
      </div>

      <button onClick={handleScroll} className="privacy-scroll-top" aria-label="Scroll to top">
        <ChevronUp />
      </button>
    </div>
  )
}

export default PrivacyPolicy

