import './Alert.css';

export function Alert({ children, variant = 'default', className = '', ...props }) {
    return (
      <div 
        role="alert"
        className={`alert ${variant} ${className}`}
        {...props}
      >
        {children}
      </div>
    )
  }
  
  export function AlertTitle({ children, className = '', ...props }) {
    return (
      <h5 
        className={`alert-title ${className}`}
        {...props}
      >
        {children}
      </h5>
    )
  }
  
  export function AlertDescription({ children, className = '', ...props }) {
    return (
      <div 
        className={`alert-description ${className}`}
        {...props}
      >
        {children}
      </div>
    )
  }
  
  