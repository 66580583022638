import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginRequest } from '../config/msalConfig';
import { useMsal } from '@azure/msal-react';
import Button from "../components/ui/Button/Button";
import { Card, CardContent } from "../components/ui/Card/Card";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faMobileAlt,
    faUserPlus,
    faChartColumn,
    faDollarSign,
    faEnvelope,
    faPhone,
} from '@fortawesome/free-solid-svg-icons';
import {
    faCircleCheck,
    faFacebook,
    faTwitter,
    faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import '../styles/LandingPage/MasterStyle.css';

export default function LandingPage() {
    const navigate = useNavigate();
    const { instance, inProgress } = useMsal();
    const [customerType, setCustomerType] = useState('');
    const [error, setError] = useState('');
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [isHeaderVisible, setIsHeaderVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            setIsHeaderVisible(currentScrollY < lastScrollY || currentScrollY < 100);
            setLastScrollY(currentScrollY);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => window.removeEventListener('scroll', handleScroll);
    }, [lastScrollY]);

    useEffect(() => {
      if (inProgress === "none") {
          setIsLoggingIn(false); // Reset login state when login flow is canceled or completed
      }
  }, [inProgress]);

    const handleLogin = async () => {
        setIsLoggingIn(true);
        setError('');
        try {
            await instance.loginRedirect({
                ...loginRequest,
                authority: 'https://bulkbuzz.b2clogin.com/bulkbuzz.onmicrosoft.com/B2C_1_signupsignin'
            });
        } catch (error) {
            console.error("Login failed: ", error);
            setError('An error occurred during login. Please try again.');
        } finally {
            setIsLoggingIn(false);
        }
    };

    const handleSignUp = async () => {

        setError('');
        try {
            await instance.loginRedirect({
                ...loginRequest,
                authority: 'https://bulkbuzz.b2clogin.com/bulkbuzz.onmicrosoft.com/B2C_1_Sign_Up'
            });
        } catch (error) {
            console.error("Sign Up failed: ", error);
            setError('An error occurred during sign up. Please try again.');
        } finally {}
    };

    const handleSubscribe = () => {
        navigate('/Businesses');
    };

    const handleViewPricing = () => {
      navigate('/choose-subscription');
  };

    return (
        <div className="lp-page">
            <header className={`lp-header ${isHeaderVisible ? '' : 'lp-header-hidden'}`}>
                <div className="lp-header-container">
                    <div className="lp-logo">
                        <span className="lp-logo-text">Bulk<span className="lp-logo-accent">Buzz</span></span>
                    </div>
                    <nav className="lp-nav">
                        <ul className="lp-nav-links">
                            <li><a href="#about" className="lp-nav-link">About</a></li>
                            <li onClick={handleSignUp}><a href="#register" className="lp-nav-link">Create Account</a></li>
                            <li onClick={handleSubscribe}><a href="#subscribe" className="lp-nav-link">Subscribe</a></li>
                            <li>
                                <Button
                                    variant="outline"
                                    className="lp-login-button"
                                    onClick={handleLogin}
                                    disabled={isLoggingIn}
                                >
                                    {isLoggingIn ? 'Logging in...' : 'Login'}
                                </Button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>

            <section className="lp-hero">
                <div className="lp-hero-content">
                    <div className="lp-hero-text-container">
                        <h1 className="lp-hero-title">
                            <span className="lp-hero-title-main">Bulk<span className="lp-hero-title-accent">Buzz</span></span>
                            <span className="lp-hero-title-sub">Connect. Engage. Grow.</span>
                        </h1>
                        <p className="lp-hero-subtitle">
                            Empower your business with direct SMS communications that keep your customers informed and engaged.
                        </p>
                        <div className="lp-hero-buttons">
                            <Button
                                size="lg"
                                variant="primary"
                                className="lp-button-primary"
                                onClick={handleSignUp}
                            >
                                Join BulkBuzz
                            </Button>
                            <Button
                                size="lg"
                                variant="secondary"
                                className="lp-button-secondary"
                                onClick={handleSubscribe}
                            >
                                Subscribe to Updates
                            </Button>
                        </div>
                    </div>
                    <div className="lp-hero-stats">
                        <div className="lp-stat">
                            <span className="lp-stat-number">5min</span>
                            <span className="lp-stat-label">Setup Time</span>
                        </div>
                        <div className="lp-stat">
                            <span className="lp-stat-number">3 Steps</span>
                            <span className="lp-stat-label">To Get Started</span>
                        </div>
                        <div className="lp-stat">
                            <span className="lp-stat-number">100%</span>
                            <span className="lp-stat-label">User-Friendly</span>
                        </div>
                    </div>
                </div>
            </section>

            <section className="lp-features" id="about">
                <div className="lp-features-container">
                    <h2 className="lp-section-title">
                        <span className="lp-title-accent">Features</span> that Drive Growth
                    </h2>
                    <div className="lp-features-grid">
                        {[
                            {
                                icon: faMobileAlt,
                                title: "Instant Communication",
                                text: "Deliver messages that matter when it matters most."
                            },
                            {
                                icon: faUserPlus,
                                title: "Easy Subscription",
                                text: "Simple opt-in process for your customers to stay connected."
                            },
                            {
                                icon: faChartColumn,
                                title: "Measurable Impact",
                                text: "Track opens, responses, and success in real time."
                            },
                            {
                                icon: faDollarSign,
                                title: "Competitive Pricing",
                                text: "Flexible plans that grow with your business needs."
                            }
                        ].map((feature, index) => (
                            <Card key={index} className="lp-feature-card">
                                <CardContent className="lp-feature-content">
                                    <div className="lp-feature-icon-wrapper">
                                        <FontAwesomeIcon icon={feature.icon} className="lp-feature-icon" />
                                    </div>
                                    <h3 className="lp-feature-title">{feature.title}</h3>
                                    <p className="lp-feature-text">{feature.text}</p>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </div>
            </section>

            <section className="lp-testimonials">
                <div className="lp-testimonials-container">
                    <h2 className="lp-section-title">
                        <span className="lp-title-accent">Success</span> Stories
                    </h2>
                    <div className="lp-testimonial">
                        <div className="lp-testimonial-content">
                            <blockquote className="lp-testimonial-quote">
                                "BulkBuzz transformed how we connect with our customers. Our event notifications now reach 95% of our audience within minutes!"
                            </blockquote>
                            <footer className="lp-testimonial-footer">
                                <div className="lp-testimonial-author">Eli Soto</div>
                                <div className="lp-testimonial-role">Marketing Director, Bulk Buzz</div>
                            </footer>
                        </div>
                    </div>
                </div>
            </section>

            <section className="lp-how-it-works" id="register">
                <div className="lp-how-it-works-container">
                    <h2 className="lp-section-title">
                        <span className="lp-title-accent">How</span> It Works
                    </h2>
                    <div className="lp-steps">
                        {[
                            {
                                title: "Create Your Account",
                                text: "Quick setup process with no technical knowledge required."
                            },
                            {
                                title: "Build Your List",
                                text: "Share your unique subscription link with customers."
                            },
                            {
                                title: "Start Engaging",
                                text: "Send targeted messages that drive action."
                            }
                        ].map((step, index) => (
                            <div key={index} className="lp-step">
                                <div className="lp-step-number">{index + 1}</div>
                                <h3 className="lp-step-title">{step.title}</h3>
                                <p className="lp-step-text">{step.text}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section className="lp-cta" id="subscribe">
                <div className="lp-cta-container">
                    <h2 className="lp-cta-title">Ready to Transform Your Customer Communication?</h2>
                    <div className="lp-cta-buttons">
                        <Button
                            size="lg"
                            variant="primary"
                            className="lp-button-primary"
                            onClick={handleSignUp}
                        >
                             Join BulkBuzz
                        </Button>
                        <Button
                            size="lg"
                            variant="secondary"
                            className="lp-pricing-button"
                            onClick={handleViewPricing}
                        >
                            View Pricing
                        </Button>
                    </div>
                </div>
            </section>

            <footer className="lp-footer">
                <div className="lp-footer-container">
                    <div className="lp-footer-content">
                        <div className="lp-footer-brand">
                            <span className="lp-footer-logo">Bulk<span className="lp-logo-accent">Buzz</span></span>
                            <p className="lp-footer-tagline">
                                Connecting businesses with customers through powerful SMS communication.
                            </p>
                        </div>
                        <div className="lp-footer-links">
                            <div className="lp-footer-section">
                                <h3 className="lp-footer-title">Company</h3>
                                <ul>
                                    <li><a href="/about">About Us</a></li>
                                    <li><a href="/contact">Contact</a></li>
                                    <li><a href="/careers">Careers</a></li>
                                </ul>
                            </div>
                            <div className="lp-footer-section">
                                <h3 className="lp-footer-title">Legal</h3>
                                <ul>
                                    <li><a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
                                    <li><a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms of Service</a></li>
                                    <li> <a href="https://gdpr-info.eu/" target="_blank" rel="noopener noreferrer">GDPR</a></li>
                                </ul>
                            </div>
                            <div className="lp-footer-section">
                                <h3 className="lp-footer-title">Contact</h3>
                                <ul>
                                    <li>
                                        <FontAwesomeIcon icon={faEnvelope} />
                                        <a href="mailto:support@bulkbuzz.net">support@bulkbuzz.net</a>
                                    </li>
                                    <li>
                                        <FontAwesomeIcon icon={faPhone} />
                                        <a href="tel:4023182990">(402) 318-2990</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="lp-footer-bottom">
                        <div className="lp-footer-social">
                            <a href="#" aria-label="Facebook">
                                <FontAwesomeIcon icon={faFacebook} />
                            </a>
                            <a href="#" aria-label="Twitter">
                                <FontAwesomeIcon icon={faTwitter} />
                            </a>
                            <a href="#" aria-label="LinkedIn">
                                <FontAwesomeIcon icon={faLinkedin} />
                            </a>
                        </div>
                        <p className="lp-footer-copyright">
                            &copy; {new Date().getFullYear()} BulkBuzz. All rights reserved.
                        </p>
                    </div>
                </div>
            </footer>
        </div>
    );
}

