import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { motion } from 'framer-motion'
import '../styles/LoadingScreen/LoadingScreen.css';

const AuthRedirect = () => {
    const navigate = useNavigate();
    const { instance } = useMsal();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const processLoginResponse = async () => {
            try {
                const response = await instance.handleRedirectPromise();
                console.log("We Made It Here 1", response);
                if (response) {
                    const email = response.account.username;

                    console.log("response.account.idTokenClaims:",  response.account.idTokenClaims);
                    console.log("response.account:",  response.account);
                    console.log("response:",  response);


                    // Check if the customer exists
                    const customerKeyResponse = await fetch(`${process.env.REACT_APP_API_URL}/customer-key?email=${encodeURIComponent(email)}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        credentials: 'include'
                    });

                    const checkData = await customerKeyResponse.json();
                    console.log("customerKeyResponse:",  customerKeyResponse);
                    if (customerKeyResponse.ok) {
                        const customerKey = checkData.customerKey; 
                        if (customerKey) {
                            setTimeout(() => {
                            // Customer exists, navigate to dashboard with the key
                            navigate(`/dashboard?customerKey=${customerKey}`);
                        }, 2000); 
                        } else {
                            // Customer does not exist, create a new customer
                            const newCustomerKey = await createCustomerInDatabase(response.account);
                            setTimeout(() => {
                            navigate(`/dashboard?customerKey=${newCustomerKey}`); // Pass the new customer key
                        }, 2000); 
                        }                                           
                    }
                }
            } catch (error) {
                console.error("Error processing login response: ", error);
            }
        };

        processLoginResponse();
    }, [instance, navigate]);

    // Function to create a new customer in the database
    const createCustomerInDatabase = async (account) => {
        // Prepare your data as before

        const customerData = {
            email: account.username,
            firstName: account.idTokenClaims.given_name,
            lastName: account.idTokenClaims.family_name,
            state: account.idTokenClaims.state,
            zip: account.idTokenClaims.postalCode,
        };

        console.log("createCustomerInDatabase:",  customerData);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/new-customer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(customerData),
            credentials: 'include'
        });

        if (!response.ok) {
            throw new Error('Failed to create customer in the database');
        }

        return (await response.json()).customerKey; // Assuming your API returns { customerKey }
    };

    return (
    <div class="honeycomb">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    </div>);
};

export default AuthRedirect;
