import { useState, useEffect, useRef } from 'react'
import './Dialog.css'

export function Dialog({ 
  isOpen, 
  onClose, 
  title, 
  children, 
  className = '' 
}) {
  const dialogRef = useRef(null)

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])

  if (!isOpen) return null

  return (
    <div className="dialog-overlay" onClick={onClose}>
      <div 
        className={`dialog ${className}`}
        ref={dialogRef}
        onClick={e => e.stopPropagation()}
      >
        <div className="dialog-header">
          <h2 className="dialog-title">{title}</h2>
          <button className="dialog-close" onClick={onClose}>×</button>
        </div>
        <div className="dialog-content">
          {children}
        </div>
      </div>
    </div>
  )
}

