import { useState, useEffect, useRef } from 'react';
import './Select.css';

export function Select({ 
  onValueChange, 
  placeholder, 
  className = '', 
  fetchUrl, 
  ...props 
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(''); // Internal state for the selected value
  const selectRef = useRef(null);

  // Fetch options from the provided URL
  useEffect(() => {
    if (fetchUrl) {
      const fetchData = async () => {
        try {
          const response = await fetch(fetchUrl, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
          });
          const data = await response.json();
          setOptions(data);
          console.log("Notification Type Data: ",data);
        } catch (error) {
          console.error('Error fetching options:', error);
        }
      };
      fetchData();
    }
  }, [fetchUrl]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSelect = (key, label) => {
    setSelectedValue(label); // Update internal selected value
    setIsOpen(false); // Close dropdown
    if (onValueChange) {
      onValueChange(key, label); // Notify parent component of the selected key
    }
  };

  return (
    <div className={`select ${className}`} ref={selectRef} {...props}>
      <button
        type="button"
        className="select-trigger"
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedValue || placeholder} {/* Show selected value or placeholder */}
      </button>

      {isOpen && (
        <div className="select-content">
          {options.map((option) => (
            <div
              key={option.flng_notification_type_key}
              className="select-item"
              onClick={() => handleSelect(option.flng_notification_type_key, option.fstr_notification_type)}
            >
              {option.fstr_notification_type}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
