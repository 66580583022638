import Button from '../Button/Button'
import { Dialog } from '../Dialog/Dialog'
import './DeleteConfirmDialog.css'

export default function DeleteConfirmDialog({
  isOpen,
  onClose,
  onConfirm
}) {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title="Delete Notification Type"
    >
      <div className="delete-confirm-content">
        <p className="delete-message">
          Are you sure you want to delete this notification type? This action cannot be undone.
        </p>
        <div className="delete-notification-dialog-actions">
          <Button className="delete-notification-cancel-button" onClick={onClose}>
            
            Cancel
          </Button>
          <Button 
            className="delete-notification-confirm-button"
            onClick={onConfirm}
          >
            Delete
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

