import { useState, useEffect } from 'react';
import { Plus } from 'lucide-react'
import Button from "../components/ui/Button/Button";
import NotificationTypeCard from '../components/ui/NotificationTypeCard/NotificationTypeCard'
import CreateNotificationDialog from '../components/ui/CreateNotificationDialog/CreateNotificationDialog'
import DeleteConfirmDialog from '../components/ui/DeleteConfirmDialog/DeleteConfirmDialog'
import EmptyState from '../components/ui/EmptyState/EmptyState'
import "../styles/NotificationTypes/MasterStyle.css";

export default function NotificationTypes() {
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
  const [deleteTypeId, setDeleteTypeId] = useState(null)


  const fetchNotificationTypes = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/notification-types`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      });
      const data = await response.json();
      setNotificationTypes(data);
    } catch (error) {
      console.error('Error fetching notification types:', error);
    }
  };

  useEffect(() => {
    fetchNotificationTypes();
  }, []);

  const handleCreateType = async (newType) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/insert-notice-type`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newType),
        credentials: 'include',
      });

      if (response.ok) {
        fetchNotificationTypes();
        setIsCreateDialogOpen(false); // Close the dialog after creation
      } else {
        console.error('Failed to create notification type');
      }
    } catch (error) {
      console.error('Error creating notification type:', error);
    }
  };

  const handleDeleteType = async (id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/delete-notice-type`, {
        method: 'POST', // Use POST for inactivation
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ notificationTypeKey: id }),
        credentials: 'include',
      });

      if (response.ok) {
        fetchNotificationTypes();
        setDeleteTypeId(null);
      } else {
        console.error('Failed to delete notification type');
      }
    } catch (error) {
      console.error('Error deleting notification type:', error);
    }
  };


  return (
    <div className="notification-types">
      <div className="notification-types-header">
        <div>
          <h1 className="notification-types-page-title">Notification Types</h1>
          <p className="notification-types-page-description">Create and manage your notification categories</p>
        </div>
        <Button onClick={() => setIsCreateDialogOpen(true)}
          className="notification-types-create-button">
          <Plus className="notification-types-button-icon" />
          Create New Type
        </Button>
      </div>

      {notificationTypes.length > 0 ? (
        <div className="notification-types-grid">
          {notificationTypes.map((type) => (
            <NotificationTypeCard
              key={type.flng_notification_type_key}
              type={type}
              onDelete={() => setDeleteTypeId(type.flng_notification_type_key)}
            />
          ))}
        </div>
      ) : (
        <EmptyState />
      )}

      <CreateNotificationDialog
        isOpen={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
        onCreate={handleCreateType}
      />

      <DeleteConfirmDialog
        isOpen={!!deleteTypeId}
        onClose={() => setDeleteTypeId(null)}
        onConfirm={() => handleDeleteType(deleteTypeId)}
      />
    </div>
  )
}

