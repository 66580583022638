import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { Check, Loader2 } from 'lucide-react'
import Button from "../components/ui/Button/Button";
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from '../components/ui/Card/Card';
import "../styles/ChooseSubscription/MasterStyle.css";
import { loginRequest } from '../config/msalConfig';

const plans = [
    {
        id: 'Essential',
        name: 'Essential Plan',
        price: '$9.99',
        description: 'Perfect for small businesses just getting started',
        features: [
            'Up to 500 messages per month',
            'One registered phone number',
            'Email support',
            'Basic analytics',
        ],
    },
    {
        id: 'Growth',
        name: 'Growth Plan',
        price: '$29.99',
        description: 'Ideal for growing businesses with moderate messaging needs',
        features: [
            'Up to 2,000 messages per month',
            'One registered phone number',
            'Priority email support',
            'Advanced analytics',
        ],
        popular: true,
    },
    {
        id: 'Enterprise',
        name: 'Enterprise Plan',
        price: '$49.99',
        description: 'For larger businesses with higher messaging demands',
        features: [
            'Up to 5,000 messages per month',
            'One registered phone number',
            'Priority email and phone support',
            'Advanced analytics and reporting',
        ],
    },
];

export default function ChooseSubscriptionPublic() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const { instance } = useMsal();
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [error, setError] = useState('');

    const handleSignUp = async () => {
        setIsLoggingIn(true);
        setError('');
        try {
            await instance.loginRedirect({
                ...loginRequest,
                authority: 'https://bulkbuzz.b2clogin.com/bulkbuzz.onmicrosoft.com/B2C_1_Sign_Up'
            });
        } catch (error) {
            console.error("Sign Up failed: ", error);
            setError('An error occurred during sign up. Please try again.');
        } finally {
            setIsLoggingIn(false);
        }
    };
    


    return (
        <div className="choose-subscription-container">
            <div class="choose-subscription-header">
                <div class="choose-subscription-header-overlay"></div>
                <div class="choose-subscription-header-content">
                    <h1 class="choose-subscription-title">Choose Your Plan</h1>
                    <p class="choose-subscription-subtitle">
                        Select a plan that works best for your business. All plans include
                        access to our powerful messaging platform.
                    </p>
                </div>
            </div>

            {/* Pricing Cards */}
            <section className="choose-subscription-plans">
                <div className="choose-subscription-plans-grid">
                    {plans.map((plan) => (
                        <Card
                            key={plan.id}
                            className={`choose-subscription-plan-card ${plan.popular ? 'plan-popular' : ''}`}
                        >
                            {plan.popular && (
                                <div className="choose-subscription-plan-popular-badge">Most Popular</div>
                            )}

                            <CardHeader className="choose-subscription-plan-header">
                                <div className="choose-subscription-plan-header-content">
                                    <CardTitle className="choose-subscription-plan-title">{plan.name}</CardTitle>
                                </div>
                            </CardHeader>
                            <CardContent>
                                <CardDescription className="choose-subscription-plan-description">{plan.description}</CardDescription>
                                <div className="choose-subscription-plan-price">
                                    <span className="price">{plan.price}</span>
                                    <span className="per-month">/month</span>
                                </div>
                                <ul className="choose-subscription-plan-features">
                                    {plan.features.map((feature, idx) => (
                                        <li key={idx} className="choose-subscription-plan-feature">
                                            <Check className="choose-subscription-plan-check-icon" />
                                            <span>{feature}</span>
                                        </li>
                                    ))}
                                </ul>
                            </CardContent>
                            <CardFooter>

                                <Button
                                    className={`choose-subscription-plan-button ${plan.popular
                                        ? 'button-yellow'
                                        : 'button-black'
                                        }`}
                                    onClick={handleSignUp}
                                    disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <>
                                            <Loader2 className="choose-subscription-button-loader" />
                                            Processing...
                                        </>
                                    ) : (
                                        'Subscribe Now'
                                    )}
                                </Button>
                            </CardFooter>
                        </Card>
                    ))}
                </div>
            </section>
            {/* choose-subscription-faq Section */}

            <div id="choose-subscription-faq-section">
                <div class="choose-subscription-faq-container">
                    <div class="choose-subscription-faq-header">
                        <h2 class="choose-subscription-faq-title">Frequently Asked Questions</h2>
                        <p class="choose-subscription-faq-description">
                            Have questions about our plans? Find quick answers below.
                        </p>
                    </div>
                    <div class="choose-subscription-faq-list-container">
                        <dl class="choose-subscription-faq-list">
                            <div class="choose-subscription-faq-item">
                                <dt class="choose-subscription-faq-question">Can I switch plans later?</dt>
                                <dd class="choose-subscription-faq-answer">
                                    Yes, you can upgrade or downgrade your plan at any time. Changes
                                    will be reflected in your next billing cycle.
                                </dd>
                            </div>
                            <div class="choose-subscription-faq-item">
                                <dt class="choose-subscription-faq-question">
                                    What happens if I exceed my monthly message limit?
                                </dt>
                                <dd class="choose-subscription-faq-answer">
                                    If you reach your monthly limit, you'll be notified and can either
                                    upgrade your plan or purchase additional messages.
                                </dd>
                            </div>
                            <div class="choose-subscription-faq-item">
                                <dt class="choose-subscription-faq-question">Is there a contract or commitment?</dt>
                                <dd class="choose-subscription-faq-answer">
                                    No, all plans are month-to-month and you can cancel at any time
                                    without penalty.
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>


        </div>
    );
}
