import './Progress.css';

export function Progress({ value = 0, max = 100, className = '', ...props }) {
  const percentage = (value / max) * 100;

  // Determine the progress bar color based on the percentage
  const getProgressBarColor = () => {
    if (percentage >= 50) return 'progress-bar-green'; 
    if (percentage >= 20) return 'progress-bar-yellow'; 
    return 'progress-bar-red'; // Safe: More than 50% messages left
  };

  return (
    <div 
      role="progressbar"
      aria-valuemin={0}
      aria-valuemax={max}
      aria-valuenow={value}
      className={`progress-container ${className}`}
      {...props}
    >
      <div 
        className={`progress-bar ${getProgressBarColor()}`}
        style={{ width: `${percentage}%` }}
      />
    </div>
  );
}
  
  