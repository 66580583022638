import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const Layout = ({ bodyStyles = {}, children }) => {
  const defaultStyles = {
    fontFamily: "'Inter', Arial, sans-serif", 
    backgroundColor: '#f0f2f5',
    margin: '0',
    padding: '0',
    height: '100vh',
    backgroundImage: 'none',
    overflowY: 'auto'
  };

  useEffect(() => {
    
    // Merge default styles with page-specific styles
    const mergedStyles = { ...defaultStyles, ...bodyStyles };

    // Apply the merged styles to the body element
    Object.keys(mergedStyles).forEach((key) => {
      document.body.style[key] = mergedStyles[key];
    });

    // Cleanup styles on unmount
    return () => {
      Object.keys(mergedStyles).forEach((key) => {
        document.body.style[key] = '';
      });
    };
  }, [bodyStyles]);

  return <div>{children}</div>;
};

Layout.propTypes = {
  bodyStyles: PropTypes.object,
  children: PropTypes.node.isRequired,
};

export default Layout;
