import { useState, useEffect } from "react";
import Button from "../components/ui/Button/Button";
import { Card, CardHeader, CardTitle, CardContent } from "../components/ui/Card/Card";
import { Select } from "../components/ui/Select/Select";
import Textarea from "../components/ui/Textarea/Textarea";
import { Users, MessageSquare, Send, Clock, ChevronRight, AlertCircle, Mail, CheckCircle2 } from "lucide-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { Dialog } from "../components/ui/Dialog/Dialog";
import "../styles/Dashboard/MasterStyle.css";

export default function Dashboard() {
  const [message, setMessage] = useState("");
  const [overallStats, setOverallStats] = useState({
    totalSubscribers: 0,
    messagesSent: 0,
    avgOpenRate: 0,
    lastCampaign: null,
  });

  const [stats, setStats] = useState({
    totalNotificationTypeSubscribers: 0,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNotifications, setIsLoadingNotifications] = useState(false);
  const [selectedTypeKey, setSelectedTypeKey] = useState('');
  const [selectedTypeLabel, setSelectedTypeLabel] = useState('');
  const [sentNotifications, setSentNotifications] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [sentMessageCount, setSentMessageCount] = useState(0);

  useEffect(() => {
    fetchOverallStats();
  }, []);


  const fetchOverallStats = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/get-overall-stats`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      if (!response.ok) {
        throw new Error("Failed to fetch overall stats.");
      }
      const data = await response.json();
      setOverallStats(data);
    } catch (error) {
      console.error("Error fetching overall stats:", error);
    }
  };

  useEffect(() => {
    if (selectedTypeKey) {
      const fetchSentNotifications = async () => {
        setIsLoadingNotifications(true);
        setSentNotifications([]); // Clear notifications to trigger the reloading animation
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/dash-sent-notifications?selectedTypeKey=${selectedTypeKey}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include' // Include cookies with request
          });

          // Check if the response is OK (status code 200)
          if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }

          const data = await response.json();

          setStats((prevStats) => ({
            ...prevStats,
            totalNotificationTypeSubscribers: data.subscriberCount,
          }));


          // Update sent notifications with a delay effect
          data.notifications.forEach((notification, i) => {
            setTimeout(() => {
              setSentNotifications((prev) => [
                ...prev,
                { ...notification, isVisible: true },
              ]);
            }, i * 200); // 200ms delay between each notification
          });

        } catch (error) {
          console.error('Error fetching sent notifications:', error);
        } finally {
          setIsLoadingNotifications(false);
        }
      };

      fetchSentNotifications();
    }

  }, [selectedTypeKey]);

  const handleSendMessage = async () => {
    if (stats.subscribers === 0) {
      setIsDialogOpen(true);
      return;
    }

    if (!selectedTypeKey || !message) {
      alert('Please select a notification type and enter a message.');
      return;
    }
    setIsSending(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/send-notification`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          flngnotificationtypekey: selectedTypeKey, // Use the selected type key
          fstrnotificationtype: selectedTypeLabel,
          fstrdescription: message, // Message input from the user
        }),
        credentials: 'include'
      });

      if (response.ok) {
        setSentMessageCount(stats.totalNotificationTypeSubscribers);
        setIsSuccessDialogOpen(true);
        setMessage(''); // Clear the message after successful send
        setSelectedTypeKey('');
        setSelectedTypeLabel(''); // Optionally clear the selected type
        fetchOverallStats();
      }
      else { throw new Error('Failed to send notification'); }
    } catch (error) {
      console.error('Error sending notification:', error);
      alert('Failed to send notification. Please try again.');
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className="dashboard">
      <div className="stats-grid">
        {[
          { title: "Total Subscribers", value: overallStats.totalSubscribers, icon: Users },
          { title: "Messages Sent", value: overallStats.messagesSent, icon: MessageSquare },
          { title: "Avg. Open Rate", value: `${overallStats.avgOpenRate}%`, icon: Send },
          {
            title: "Last Campaign",
            value: overallStats.lastCampaign ? (
              new Date(overallStats.lastCampaign).getTime() > Date.now() - 24 * 60 * 60 * 1000
                ? new Intl.RelativeTimeFormat('en', { numeric: 'auto' }).format(
                  -Math.round((Date.now() - new Date(overallStats.lastCampaign).getTime()) / (60 * 60 * 1000)),
                  'hours'
                )
                : new Intl.DateTimeFormat('en', {
                  month: 'short',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true
                }).format(new Date(overallStats.lastCampaign))
            ) : "N/A",
            icon: Clock
          },
        ].map((stat, index) => (
          <Card key={index} className="stat-card">
            <CardHeader>
              <CardTitle>{stat.title}</CardTitle>
              <stat.icon className="stat-icon" />
            </CardHeader>
            <CardContent>
              <div className="stat-value">{stat.value}</div>
            </CardContent>
          </Card>
        ))}
      </div>

      <div className="full-width-container">
        <Card className="compose-card">
          <CardHeader>
            <CardTitle>Compose Message</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="compose-content">
              <label className="compose-label">Notification Type</label>
              <Select
                onValueChange={(selectedKey, selectedLabel) => {
                  setSelectedTypeKey(selectedKey); // Update the key
                  setSelectedTypeLabel(selectedLabel); // Update the label
                  console.log('Selected Key:', selectedKey, 'Selected Label:', selectedLabel); // Debug log
                }}
                placeholder="Select Notification Type"
                fetchUrl={`${process.env.REACT_APP_API_URL}/notification-types`}
              />
              {selectedTypeKey && (
                <div className="subscriber-count">
                  <Users className="subscriber-icon" />
                  <span>
                    {stats.totalNotificationTypeSubscribers === 1
                      ? "1 subscribers will receive this message"
                      : `${stats.totalNotificationTypeSubscribers} subscribers will receive this message`}
                  </span>
                </div>
              )}
              <Textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="compose-textarea"
                placeholder="Type your message here..."
              />
              <div className="compose-footer">
                <span className="char-count">{message.length}/160 characters</span>
                <Button
                  className="send-button"
                  onClick={handleSendMessage}
                  disabled={!selectedTypeLabel || !message || isLoading}
                >
                  {isLoading ? 'Sending...' : 'Send Message'}

                  <FontAwesomeIcon icon={faPaperPlane} className="send-message-icon" />
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card className="recent-messages-card">
          <CardHeader>
            <CardTitle>Recent Messages</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="messages-list">
              {isLoadingNotifications ? (
                <p>Loading notifications...</p>
              ) : sentNotifications.length === 0 ? (
                <p>No notifications sent yet.</p>
              ) : (
                sentNotifications.map((notification, index) => (
                  <div key={index} className={`message-item ${notification.isVisible ? 'visible' : ''}`}>
                    <div className="message-content">
                      <p className="message-text">{notification.fstr_description}</p>
                      <span className="message-time">{new Date(notification.fdtm_sent).toLocaleString()}</span>
                    </div>
                    <div className="message-stats">
                      <span>{notification.opens} opens</span>
                      {/*<span>{notification.clicks} clicks</span>*/}
                      <ChevronRight className="chevron-icon" />
                    </div>
                  </div>
                ))
              )}
            </div>

          </CardContent>
        </Card>
      </div>

      {/* Dialog Component */}
      <Dialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        title="No Subscribers Found"
      >
        <div className="no-subscribers-content">
          <div className="no-subscribers-icon-wrapper">
            <Users className="no-subscribers-icon" />
            <AlertCircle className="alert-icon" />
          </div>
          <div className="no-subscribers-message">
            <h3 className="no-subscribers-title">Oops! Looks like you're just getting started</h3>
            <p className="no-subscribers-description">
              You currently don’t have any subscribers for this notification type.
              Start by inviting people to subscribe and grow your list! Once you have subscribers,
              you'll be able to send them updates and messages.
            </p>
          </div>
          <div className="dialog-actions">
            <Button onClick={() => setIsDialogOpen(false)} className="no-subscribers-button">
              Got it! I'll add some subscribers
            </Button>
          </div>
        </div>
      </Dialog>

      {/* Success Dialog */}
      <Dialog
        isOpen={isSuccessDialogOpen}
        onClose={() => setIsSuccessDialogOpen(false)}
        title="Message Sent Successfully!"
      >
        <div className="success-dialog-content">
          <div className="success-dialog-icon-wrapper">
            <Mail className="flying-envelope-icon" />      
           
          </div>
          <div className="success-dialog-message">
            <h3 className="success-dialog-title">Great news!</h3>
            <p className="success-dialog-description">
              Your message has been successfully sent to{" "}
              <strong>
                {sentMessageCount} {sentMessageCount === 1 ? "subscriber" : "subscribers"}
              </strong>
              !
            </p>
            <div className="success-stats">
              <div className="success-stat">
                <MessageSquare className="success-stat-icon" />
                <span>Delivered</span>
              </div>
              <div className="success-stat">
                <Clock className="success-stat-icon" />
                <span>Just now</span>
              </div>
            </div>
          </div>
          <div className="dialog-actions">
            <Button
              onClick={() => setIsSuccessDialogOpen(false)}
              className="success-dialog-button"
            >
              Awesome!
            </Button>
          </div>
        </div>
      </Dialog>


    </div>
  );
}

