import React, { useState, useEffect } from 'react';
import { useLocation, Outlet, Link } from 'react-router-dom';
import { Users, MessageSquare, Settings, Bell, Menu, Building2, LogOut, CreditCard  } from 'lucide-react';
import Button from '../ui/Button/Button';
import { useMsal } from '@azure/msal-react'; 
import '../../styles/Dashboard/Layout.css';

function SidebarContent({ navigation }) {
    return (
      <div className="sidebar-content">
        {navigation.map((item) => {
          const Icon = item.icon;
          const linkClass =
          item.name === 'Subscription'
            ? 'subscription-nav-button' 
            : 'nav-button';
          return (
            <Link
              key={item.name}
              to={item.href}
              className={`${linkClass} ${item.current ? 'active' : ''}`}
            >
              <Icon className="nav-icon" />
              <span className="nav-link-text">{item.name}</span>
            </Link>
          );
        })}
      </div>
    );
  }

export default function DashboardLayout() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();
  const { instance }  = useMsal();
  const [isLoaded, setIsLoaded] = useState(false);
  const isActive = (path) => location.pathname === path;


  useEffect(() => {
    // Trigger the fade-in effect once the component is mounted
    const timer = setTimeout(() => {
        setIsLoaded(true);
    }, 500); // Small delay for smooth effect

    return () => clearTimeout(timer); // Clean up timer
}, []);

if (!isLoaded) {
    return <div className="loading-placeholder" />;
}


  const navigation = [
    { name: 'Messages', href: '/dashboard', icon: MessageSquare, current: isActive('/dashboard') },
    { name: 'Subscribers', href: '/dashboard/Subscribers', icon: Users, current: isActive('/dashboard/Subscribers') },
    { name: 'Business Settings', href: '/dashboard/BusinessSettings', icon: Building2, current: isActive('/dashboard/BusinessSettings') },
    { name: 'Notifications', href: '/dashboard/NotificationTypes', icon: Bell, current: isActive('/dashboard/NotificationTypes') },
    { name: 'Subscription', href: '/dashboard/Subscription', icon: CreditCard, current: isActive('/dashboard/Subscription') },
  ];

  const handleLogout = async () => { 
    try {
      await instance.logoutRedirect({
        postLogoutRedirectUri: window.location.origin + "/Home",
      });
    } catch (error) {
      console.error("Error during logoutRedirect:", error);
    }
};

  return (
    <div className={`dashboard-layout ${isLoaded ? 'fade-in' : ''}`}>
      <header className="mobile-header">
        <h1 className="logo">Bulk Buzz</h1>
        <Button
          variant="ghost"
          className="menu-button"
          aria-expanded={isSidebarOpen}
          aria-label="Toggle Sidebar"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          <Menu className="menu-icon" />
        </Button>
      </header>

      <div className="layout-container">
        <aside className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
          <div className="sidebar-header">
            <h1 className="sidebar-title">Bulk Buzz</h1>
            <p className="sidebar-subtitle">Business Dashboard</p>
          </div>
          <nav className="sidebar-nav">
            <SidebarContent navigation={navigation} />
            <div className="separator-nav">
              <Button
                variant="ghost"
                className="logout-button"
                onClick={handleLogout}>
                <span className="logout-link">
                  <LogOut className="logout-icon" />
                  Logout
                </span>
              </Button>
            </div>
          </nav>
        </aside>

        <main className="main-content">
          <Outlet /> {/* Render the child components here */}
        </main>
      </div>
    </div>
  );
}
