import { useState, useEffect, useRef  } from 'react';
import { QRCodeCanvas } from 'qrcode.react'; 
import { Building2, MapPin, Phone, Globe, Share2, QrCode, Copy, Download } from 'lucide-react'
import Button from "../components/ui/Button/Button";
import Input from '../components/ui/Input/Input'
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/Card/Card'
import Label from '../components/ui/Label/Label'
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
  } from '../components/ui/Dialog/Dialog'
import "../styles/BusinessSettings/MasterStyle.css";

export default function BusinessSettings() {
    const [businessInfo, setBusinessInfo] = useState({
      name: '',
      street: '',
      city: '',
      state: '',
      zipCode: '',
      areaCode: '', 
      phone: '',
      website: '',
    });

    const [initialBusinessInfo, setInitialBusinessInfo] = useState({});
    const [isProcessing, setIsProcessing] = useState(false);
    const [showCopiedTooltip, setShowCopiedTooltip] = useState(false)
    const [subscribeUrl, setSubscribeUrl] = useState('');
    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const qrCodeRef = useRef();

    useEffect(() => {
        const fetchBusinessInfo = async () => {
          try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/get-business-info`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include',
            });
            if (response.ok) {
              const data = await response.json();
              setBusinessInfo({
                name: data.fstr_business_name,
                street: data.fstr_street,
                city: data.fstr_city,
                state: data.fstr_state,
                zipCode: data.fstr_zip,
                areaCode: data.fstr_area_code || '', // Fetch and set Area Code
                phone: data.fstr_phone || '',
                website: data.fstr_website || '',
                customerKey: data.flng_customer_key || '',
              });
              setInitialBusinessInfo(data); // Save initial state for reset
              const encodedName = encodeURIComponent(data.fstr_business_name);
              const url = `https://bulkbuzz.net/subscribe?customerKey=${data.flng_customer_key}&zip=${data.fstr_zip}&businessName=${encodedName}`;
              setSubscribeUrl(url);
              setQrCodeUrl(url);
            } else {
              console.error('Failed to fetch business information.');
            }
          } catch (error) {
            console.error('Error fetching business information:', error);
          }
        };
    
        fetchBusinessInfo();
      }, []);


   const handleSave = async () => {
    if (isProcessing) return;

    setIsProcessing(true);

    const updatedBusinessInfo = {
      fstr_business_name: businessInfo.name,
      fstr_street: businessInfo.street,
      fstr_city: businessInfo.city,
      fstr_state: businessInfo.state,
      fstr_zip: businessInfo.zipCode,
      fstr_area_code: businessInfo.areaCode, 
      fstr_phone: businessInfo.phone,
      fstr_website: businessInfo.website,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/insert-business-info`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedBusinessInfo),
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setInitialBusinessInfo(data); 
        setBusinessInfo({
          name: data.fstr_business_name,
          street: data.fstr_street,
          city: data.fstr_city,
          state: data.fstr_state,
          zipCode: data.fstr_zip,
          areaCode: data.fstr_area_code || '',
          phone: data.fstr_phone || '',
          website: data.fstr_website || '',
        });
         // Regenerate URLs after save
         const encodedName = encodeURIComponent(data.fstr_business_name);
         const url = `https://bulkbuzz.net/subscribe?customerKey=${data.fstr_customer_key}&zip=${data.fstr_zip}&businessName=${encodedName}`;
         setSubscribeUrl(url);
         setQrCodeUrl(url);
        alert('Business information saved successfully!');
      } else {
        console.error('Failed to save business information.');
      }
    } catch (error) {
      console.error('Error saving business information:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const downloadQRCode = () => {
    const canvas = qrCodeRef.current.querySelector('canvas'); // Reference the QR code canvas
    if (canvas) {
      const image = canvas.toDataURL('image/png'); // Get canvas data as PNG
      const link = document.createElement('a'); // Create a download link
      link.href = image;
      link.download = 'subscription-qr-code.png'; // Set the file name
      link.click(); // Trigger the download
    }
  };
  
  const copySubscribeLink = () => {
    navigator.clipboard.writeText(subscribeUrl)
    setShowCopiedTooltip(true)
    setTimeout(() => setShowCopiedTooltip(false), 2000)
  }


  return (
    <div className="business-settings-container">
    <h1 className="business-settings-title">Business Settings</h1>
    <p className="business-settings-instruction">
      Add your business details to let subscribers find you.
    </p>
  
    {/* Business Information Card */}
    <Card className="business-settings-card">
      <CardHeader>
        <CardTitle className="card-header-title">
          <Building2 className="card-header-icon" />
          Business Information
        </CardTitle>
      </CardHeader>
      <CardContent>
        {/* Business Information Form */}
        <div className="form-grid">
          {/* Form Fields */}
          <div className="form-group">
            <Label htmlFor="businessName">Business Name</Label>
            <Input
              id="businessName"
              value={businessInfo.name}
              onChange={(e) => setBusinessInfo({ ...businessInfo, name: e.target.value })}
            />
          </div>
            <div className="form-group">
              <Label htmlFor="phone">Phone Number</Label>
                <Input
                  id="phone"
                  value={businessInfo.phone}
                  onChange={(e) => {
                    const formattedPhone = formatPhoneNumber(e.target.value);
                    setBusinessInfo({ ...businessInfo, phone: formattedPhone });
                  }}
                />
            </div>
          </div>

           {/* Website and Area Code Row */}
      <div className="form-grid-2">
        <div className="form-group">
          <Label htmlFor="website">Website</Label>
          <Input
            id="website"
            value={businessInfo.website}
            onChange={(e) => setBusinessInfo({ ...businessInfo, website: e.target.value })}
          />
        </div>
        <div className="form-group">
          <Label htmlFor="areaCode">Area Code</Label>
          <Input
            id="areaCode"
            value={businessInfo.areaCode}
            onChange={(e) => setBusinessInfo({ ...businessInfo, areaCode: e.target.value })}
          />
        </div>
      </div>

      <div className="form-group">
            <Label htmlFor="street">Street Address</Label>
            <Input
              id="street"
              value={businessInfo.street}
              onChange={(e) => setBusinessInfo({ ...businessInfo, street: e.target.value })}
            />
          </div>
  
        <div className="form-grid-3">
          <div className="form-group">
            <Label htmlFor="city">City</Label>
            <Input
              id="city"
              value={businessInfo.city}
              onChange={(e) => setBusinessInfo({ ...businessInfo, city: e.target.value })}
            />
          </div>
          <div className="form-group">
            <Label htmlFor="state">State</Label>
            <Input
              id="state"
              value={businessInfo.state}
              onChange={(e) => setBusinessInfo({ ...businessInfo, state: e.target.value })}
            />
          </div>
          <div className="form-group">
            <Label htmlFor="zipCode">ZIP Code</Label>
            <Input
              id="zipCode"
              value={businessInfo.zipCode}
              onChange={(e) => setBusinessInfo({ ...businessInfo, zipCode: e.target.value })}
            />
          </div>
        </div>
  
        <Button
          onClick={handleSave}
          variant="primary"
          className="save-button"
          disabled={isProcessing}
        >
          {isProcessing ? 'Saving...' : 'Save Changes'}
        </Button>
      </CardContent>
    </Card>
  
 {/* Share Link Section */}

 <Card className="subscription-card">
  <CardHeader>
    <CardTitle className="card-header-title">
      <Share2 className="card-header-icon" />
      Subscription Link
    </CardTitle>

  </CardHeader>
  <CardContent>
  <p className="text-sm text-gray-500">
            Share this link with your customers so they can subscribe to your notifications.
          </p>
    <div className="subscription-card-content">
     
      {/* Subscription Link Section */}
      <div className="subscription-link-section">
        <div className="subscription-link-wrapper">
          <Input
            value={subscribeUrl}
            readOnly
            className="subscription-link-input"
          />
          <Button
            variant="secondary"
            className="copy-button"
            onClick={copySubscribeLink}
          >
            <Copy className="button-icon" />
            {showCopiedTooltip ? 'Copied!' : 'Copy'}
          </Button>
        </div>

         {/* QR Code Container */}
         <div ref={qrCodeRef} className="qr-code-container">
            <QRCodeCanvas value={subscribeUrl} size={150} /> {/* Render QR code */}
          </div>
          <Button variant="secondary" className="qr-download-button" onClick={downloadQRCode}>
            <Download className="button-icon" />
            Download QR Code
          </Button>
      </div> 
    </div>
  </CardContent>
</Card>
</div>
  );

  function formatPhoneNumber(value) {
    // Remove all non-digit characters
    const cleaned = value.replace(/\D/g, '');
    
    // Format the phone number based on the number of digits
    if (cleaned.length <= 3) {
      return cleaned;
    } else if (cleaned.length <= 6) {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
    } else {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
    }
  }
}